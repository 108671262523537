import { useHttp, type HttpOption } from '../composables/useHttp'
import type { IUserGiftFilterParams } from './types/filter'

export interface IUserGiftListItem {
  id: number
  event_id: number
  event_type: string
  event_subject: string
  sale_person: string
  txt_diff_gift: string
  invoice_number: string
  invoice_date: string
  invoice_amount: string
  approval_date: string
  delivery_serial: string
  review_status: string
  gift_status: string
  reason: string
  memo: string
  can_reupload: boolean
  created_at: string
}

export interface IUserGiftProduct {
  id: number
  brand_id: number
  brand_name: string
  model_id: number
  model_name: string
  type_id: string
  type_name: string
  serial: string
  diff_text: string
  if_return: string
  channel_id: number
  channel_name: string
}

export interface IUserGift {
  id: number
  event_type: string
  event_id: number
  event_subject: string
  sale_person: string
  txt_diff_gift: string
  invoice_number: string
  invoice_date: string
  invoice_amount: string
  receive_name: string
  receive_phone: string
  receive_zip_code: string
  receive_city: string
  receive_town: string
  receive_address: string
  approval_date: string
  delivery_serial: string
  review_status: string
  reason: string
  memo: string
  can_reupload: boolean
  items: Array<IUserGiftProduct>
  gift_status: string
  created_at: string
}

enum Api {
  gifts = '/user/gift_events', // 贈品列表
  gift = '/user/gift_events/:id', // 贈品詳情
  reuploadGiftImage = '/user/upload_gift_medias/:id' // 重新上傳贈品圖片
}

// 查詢會員贈品列表
export function getGifts(filter: IUserGiftFilterParams, option?: HttpOption<any>) {
  return useHttp.get<Array<IUserGiftListItem>>(Api.gifts, filter, option)
}

// 取得會員贈品詳情
export function getGift(id: string, option?: HttpOption<any>) {
  return useHttp.get<IUserGift>(Api.gift.replace(':id', id), option)
}
