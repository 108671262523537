import { ElMessage } from 'element-plus'
import type { FetchResponse, SearchParameters } from 'ofetch'
import type { Ref } from 'vue'
import type { UseFetchOptions } from '#app'
import type { IMeta, ILinks } from '~/api/types/response'

export interface ResOptions<T> {
  data: T
  token?: T
  code: number
  message: string
  success: boolean
  meta?: IMeta
  links?: ILinks
}

type UrlType = string | Request | Ref<string | Request> | (() => string | Request)

export type HttpOption<T> = UseFetchOptions<ResOptions<T>>

function handleError<T>(response: FetchResponse<ResOptions<T>> & FetchResponse<ResponseType>) {
  const err = (text: string) => {
    ElNotification({
      message: response?._data?.message ?? text
    })
  }
  if (!response._data) {
    err('請求超時，伺服器無回應！')
    return
  }
  const userStore = useUserStore()
  const handleMap: { [key: number]: () => void } = {
    404: () => err('伺服器資源不存在'),
    500: () => err('伺服器內部錯誤'),
    403: () => err('沒有權限存取該資源'),
    401: () => {
      err('登入狀態已過期，需要重新登入')
      // userStore.clearUserInfo()
      navigateTo('/')
    }
  }
  // handleMap[response.status] ? handleMap[response.status]() : err('未知錯誤！')
  // handleMap[response.status] ? handleMap[response.status]() : () => {}
}

function paramsSerializer(params?: SearchParameters) {
  if (!params) return

  const query = useCloneDeep(params)
  Object.entries(query).forEach(([key, val]) => {
    if (typeof val === 'object' && Array.isArray(val) && val !== null) {
      query[`${key}[]`] = toRaw(val).map((v: any) => JSON.stringify(v))
      delete query[key]
    }
  })
  return query
}
function fetch<T>(url: UrlType, option: HttpOption<T>) {
  return useFetch<ResOptions<T>>(url, {
    onRequest({ options }) {
      options.params = paramsSerializer(options.params)
      const {
        public: { apiBase }
      } = useRuntimeConfig()
      options.baseURL = apiBase

      const userStore = useUserStore()
      if (!userStore.isLogin) return
      options.headers = new Headers(options.headers)
      options.headers.set('Authorization', `Bearer ${userStore.token?.token}`)
      options.headers.set('Accept', 'application/json')
    },
    onResponse({ response }) {
      if (response.status === 200) return null
      return response._data
    },
    onResponseError({ response }) {
      handleError<T>(response)
      return Promise.reject(response?._data)
    },
    ...option
  })
}

export const useHttp = {
  get: <T>(url: UrlType, params?: any, option?: HttpOption<T>) => {
    return fetch<T>(url, { method: 'get', params, ...option })
  },

  post: <T>(url: UrlType, body?: any, option?: HttpOption<T>) => {
    return fetch<T>(url, { method: 'post', body, ...option })
  },

  put: <T>(url: UrlType, body?: any, option?: HttpOption<T>) => {
    return fetch<T>(url, { method: 'put', body, ...option })
  },

  delete: <T>(url: UrlType, body?: any, option?: HttpOption<T>) => {
    return fetch<T>(url, { method: 'delete', body, ...option })
  }
}
